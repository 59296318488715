import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import AdvertPortfolio from "../components/AdvertPortfolio"

const advertisements = () => (
  <Layout>
    <Seo title="Advertisements Portfolio" />
    
    <AdvertPortfolio />
  </Layout>
)



export default advertisements
