import React, {useEffect} from 'react'
import { Link } from 'gatsby'

const AdvertPortfolio = () => {

     //Create intersection observer for video autoplay 
     useEffect(() => {
        const targets = document.querySelectorAll("video");

            const playVideo = (target)=>{
                
                
            const io = new IntersectionObserver((entries,observer)=>{
                entries.forEach(entry=>{
                    if(entry.isIntersecting){
                        const video=entry.target;
                        video.loop = true;
                        video.muted = true
                        
                        console.log('video on screen')
                        toggleVideo(video)
                       // observer.disconnect();
                    }
                })
            },{threshold:[0.7]});

            io.observe(target);

            //Function to play video
            const toggleVideo = async(targetVideo) => {
                try {
                   await targetVideo.play()
                } catch (error) {
                    console.log(error)
                }
            }
            }
            targets.forEach(playVideo);
    },[])

    const projects = require('../../data/adverts.json');

    return (
        <section id = 'advert-section' style = {sectionStyle}>
            
            {
                projects.map(project => {
                    return (
                        <div className = 'row' style = {rowStyle}>
                            <h1 className = 'advert-heading'>{project.name && project.name}</h1>
                            {
                                project.media.map(media => {
                                    return (
                                    <div className = {`${media.col} mt-3`}> 
                                        <video style = {videoStyle} playsInline controls>
                                            <source src = {`${media.src}`} ></source>
                                        </video>
                                    </div>
                                    )
                                })
                            }
                        </div>
                    )
                })
            }


        </section>
    )
}

const sectionStyle = {
    minHeight:'100vh',
    paddingLeft:'200px',
    paddingRight:'200px',
}

const rowStyle = {
    //minHeight:'350px',
    paddingTop:'70px'
}

const videoStyle = {
    width:'100%',
    height:"100%"
}



export default AdvertPortfolio
